import React, { FC } from "react";
import { PreviousReviewIcon } from "../elements/iconElements";
import { PreviousReviewButtonContainer } from "./elements";

interface IPreviousReviewButton {
  onClick: () => void
}

export const PreviousReviewButton: FC<IPreviousReviewButton> = ({ onClick }) => {
  return (
    <PreviousReviewButtonContainer onClick={onClick}>
      <PreviousReviewIcon />
    </PreviousReviewButtonContainer>
  )
}
