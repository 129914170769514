import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby";
import useContent from "../../../hooks/useContent";
import { Container, VerticalSpacer, ReviewsTitle, ReviewerName, ReviewContent } from "../../../common/elements"
import { SecondaryButton } from "../../../common/components"
import Rating, { ShowStars } from "../../../components/rating"
import { CustomRatingContainer } from "../../../components/styled/rating-styled"
import {
  ReviewButtonsContainer,
  OveralRating,
  MainReviewsContainer,
  ReviewRatingAndContentContainer,
  TotalReviewsContainer,
  TotalRatings,
  SingleReviewContainer
} from "../components/elements"
import { NextReviewButton, PreviousReviewButton } from '../components'
const query = graphql`
query {
  ratings: allYotpoProductBottomline {
    nodes {
      productIdentifier
      totalReviews
      score
    }
  }
  reviews: allYotpoProductReview {
    nodes {
      score
      createdAt
      name
      content
    }
  }
}
`

interface IRatings {
  nodes: { score: number }[]
}

interface IReview {
  score: number,
  createdAt: string,
  name: string,
  content: string
}

export const ReviewsSection = () => {
  const pageName = 'theBike'
  const sectionKey = "reviewsSection";
  const { content } = useContent(pageName);
  const { ratings, reviews: { nodes } } = useStaticQuery(query)

  const [reviews, setReviews] = useState([])
  useEffect(() => {
    if (nodes[0].createdAt > nodes[nodes.length - 1].createdAt) {
      setReviews(nodes)
    } else {
      setReviews(nodes.reverse())
    }
  }, [nodes])

  const DEFAULT_REVIEW = {
    currentIndex: -1,
    score: content([sectionKey, 'showcasedRating']),
    createdAt: '',
    name: content([sectionKey, 'showcasedReviewerName']),
    content: content([sectionKey, 'showcasedReview'])
  }

  const [selectedReview, setSelectedReview] = useState<IReview & { currentIndex: number }>(DEFAULT_REVIEW)

  const calculateAvarageRatings = (ratings: IRatings): number => {
    const { nodes } = ratings
    const total = nodes[0] && nodes[0].score
    return total
  }

  const nextReview = (reviews: IReview) => {
    const currentIndex = selectedReview.currentIndex === -1 ? 0 : selectedReview.currentIndex + 1;
    setSelectedReview({ ...reviews[currentIndex], currentIndex })
  }

  const prevReview = (reviews: IReview) => {
    if (selectedReview.currentIndex === 0) {
      return setSelectedReview(DEFAULT_REVIEW)
    }
    const currentIndex = selectedReview.currentIndex === -1 ? 0 : selectedReview.currentIndex - 1;
    setSelectedReview({ ...reviews[currentIndex], currentIndex })
  }

  const formatContent = (content: string): string => {
    if (content.length > 500) {
      return `${content.slice(0, 498)}...`;
    }
    return content;
  }


  return (
    <Container>
      <VerticalSpacer extraExtraLarge />
      <MainReviewsContainer>
        <TotalReviewsContainer>
          <ReviewsTitle>
            {content([sectionKey, 'title'])}
          </ReviewsTitle>
          <OveralRating>
            <Rating rating={calculateAvarageRatings(ratings)} />
            <TotalRatings>{ratings.nodes[0].totalReviews} {content([sectionKey, 'reviews'])}</TotalRatings>
          </OveralRating>
          <VerticalSpacer large />
        </TotalReviewsContainer>
        <SingleReviewContainer>
          <ReviewRatingAndContentContainer>
            <CustomRatingContainer>
              <ShowStars rating={selectedReview.score} />
            </CustomRatingContainer>
            <ReviewContent dangerouslySetInnerHTML={{ __html: `“${formatContent(selectedReview.content)}”` }}>
              {/* {selectedReview.content} */}
            </ReviewContent>
          </ReviewRatingAndContentContainer>
          <ReviewerName>
            {selectedReview.name}
          </ReviewerName>
          <VerticalSpacer large />
          <ReviewButtonsContainer>
            {selectedReview.currentIndex !== -1 && <PreviousReviewButton onClick={() => prevReview(reviews)} />}
            <NextReviewButton onClick={() => nextReview(reviews)} />
          </ReviewButtonsContainer>
        </SingleReviewContainer>
      </MainReviewsContainer>
      <VerticalSpacer extraExtraLarge />
    </Container>
  )
}
