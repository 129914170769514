import React, { FC } from "react";
import { NextReviewIcon } from "../elements/iconElements";
import { NextReviewButtonContainer } from "./elements";

interface INextReviewButton {
  onClick: () => void
}

export const NextReviewButton: FC<INextReviewButton> = ({ onClick }) => {
  return (
    <NextReviewButtonContainer onClick={onClick}>
      <NextReviewIcon />
    </NextReviewButtonContainer>
  )
}
