import styled from "styled-components"
import { TinyText } from '../../../../common/elements'
import { BREAK_POINTS, FONT_FAMILY } from "../../../../common/constants"

export const MainReviewsContainer = styled.div`
  display: flex;
  @media (max-width: ${BREAK_POINTS.SMALL_DEVICE}) {
    flex-direction: column;
  }
`

export const TotalReviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  @media (max-width: ${BREAK_POINTS.SMALL_DEVICE}) {
    align-items: center;
  }
`
export const SingleReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
  flex: 7;
  @media (max-width: ${BREAK_POINTS.SMALL_DEVICE}) {
    align-items: center;
    margin: 1rem 0 0 0;
  }
`

export const ReviewRatingAndContentContainer = styled.div`
  display: flex;
  margin: 10px 0 0 0;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  @media (max-width: ${BREAK_POINTS.SMALL_DEVICE}) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const OveralRating = styled.div`
  margin: 1rem 0 0 0;
  cursor: unset;
  display: flex;
  align-items: center;
`

export const TotalRatings = styled(TinyText)`
  font-family: ${FONT_FAMILY};
  text-decoration: underline;
  font-size: 14px;
  margin: 0 0 0 10px;
`

export const NextReviewButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

export const PreviousReviewButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`

export const ReviewButtonsContainer = styled.div`
  display: flex;
  flex-direction: row; 
  width: 100%;
`